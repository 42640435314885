import React from 'react';
import './contact.css';
import SendMessage from '../components/sendmessage';
import Footer from '../components/footer';

const Contact = (props) => {
    
    return (
        <section className="page page-contact section-2fr">
            <div className="send-message">
                <SendMessage />
            </div>
            <Footer />
        </section>
    );
};

Contact.propTypes = {};

export default Contact;
