import React from 'react';
import './projects.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/footer';
import Project from '../components/project';

import SendMessage from '../components/sendmessage';
import projectLogo from '../assets/images/rubiks.png'
import projects from '../assets/static/data/projects.json';

const Projects = (props) => {
    return (
        <section className="page page-projects section-3fr">
            <div className="projects">
                <h1 className="jf-m-bottom_xlarge">
                    <img src={projectLogo} alt="Projects" />
                    <p className="title">{projects.title}</p>
                    <p className="subtitle">{projects.subtitle}</p>
                </h1>
                <p className="content jf-m-bottom_xlarge">{projects.content}</p>
                <div>
                    {projects.personal.map((item, index) => {
                        return (
                            <div key={index} className="proj-item">
                                <Project props={item} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="project-contact component-divider">
                <SendMessage />
            </div>
            <Footer />
        </section>
    );
};

Projects.propTypes = {};

export default Projects;
