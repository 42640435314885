import React from 'react';
import PropTypes from 'prop-types';

import './project.css';
import Button from '../components/button';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Project = ({ props }) => {

    let certClass = 'project flex flex-direction-row flex-align-center flex-wrap';
    let logoUrl = props.logo ? require(`../assets/images/${props.logo}`) : '';

    const navigate = useNavigate();

    function navto(path) {
        if (path.indexOf('https') !== -1) {
            window.open(path, '_blank');
            return;
        }
        navigate(path);
    }
    return (
        
        <div className={certClass}>
            <div className="proj-logo"
                 data-aos="fade-up"
                 data-aos-duration="600"
                 data-aos-easing="ease-in-sine">
                {
                    logoUrl ? (
                        <img src={logoUrl} alt={props.title} className="icon-proj" />
                    ) : ''
                }
            </div>
            <div className="proj-content">
                <div>
                    <p className="title"
                       data-aos="fade-up"
                       data-aos-duration="400"
                       data-aos-easing="ease-in-sine">
                        <span className="text-brand">{props.title}</span>
                    </p>
                    <p className="subtitle"
                       data-aos="fade-up"
                       data-aos-duration="800"
                       data-aos-easing="ease-in-sine">
                        {props.subtitle}
                        {
                            props.private ? (
                                <FontAwesomeIcon icon="fa-solid fa-lock" className="icon-lock jf-p-left_xsmall" size="2xs"/>
                            ) : ''
                        }
                    </p>
                    <p className="content jf-m-vertical_large"
                       data-aos="fade-up"
                       data-aos-duration="1000"
                       data-aos-easing="ease-in-sine">
                        {props.content}
                    </p>
                    {
                        props.notes ? (
                            <p className="notes jf-m-vertical_large"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-sine">
                                {props.notes}
                            </p>
                        ) : ''
                    }
                    
                    <div className="proj-btn">
                        {
                            props.btnLabel ? (
                                 <Button
                                    props={{
                                        type: "brand",
                                        label: props.btnLabel,
                                        icon: props.btnIcon,
                                        data: props.btnLink,
                                        handleClick: navto,
                                    }}
                                />
                            ) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

Project.propTypes = {
    name: PropTypes.string,
    provider: PropTypes.string,
    issued_date: PropTypes.string,
    expiration: PropTypes.string,
    status: PropTypes.string,
    logo: PropTypes.string
};

export default Project;
