import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./landing.css";

import Button from "./button";
import user from "../assets/static/data/user.json";

import util from '../components/util';

const Landing = (props) => {
    useEffect(() => {
        // on ready
    }, []);

    const navigate = useNavigate();

    function navto(path) {
        navigate(path);
    }

    let user_bio = util.formatText(user.bio,
        [new Date().getFullYear() - user.sf_start_year]);

    return (
        <section className="page page-landing main-background">
            <div className="intro">
                <div className="intro-splash"></div>
                <div className="intro-greet">
                    <p className="title"
                       data-aos="fade-up"
                       data-aos-duration="700"
                       data-aos-easing="ease-in-sine">
                        <span>Hello, </span><br />I'm <span className="text-brand">{user.first_name}</span>
                    </p>
                    <p className="role subtitle"
                       data-aos="fade-up"
                       data-aos-easing="ease-in-sine"
                       data-aos-duration="900">
                        {user.role}
                    </p>
                    <p className="bio content jf-m-vertical_medium"
                       data-aos="fade-up"
                       data-aos-easing="ease-in-sine"
                       data-aos-duration="1100">
                        {user_bio}
                    </p>
                    <div className="btn-about jf-m-vertical_large"
                         data-aos="fade-up"
                         data-aos-easing="ease-in-sine"
                         data-aos-duration="1300">
                        <Button
                            props={{
                                type: "brand",
                                label: "About me",
                                icon: "fa-regular fa-user",
                                data: "/about",
                                handleClick: navto,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="hero">
                <div className="hero-image"></div>
            </div>
        </section>
    );
};

Landing.propTypes = {};

export default Landing;
