import React from "react";
import PropTypes from "prop-types";

const Work = (props) => {
	return (
        <div>
            <section className="banner">
                <div className="coming-soon">Coming Soon...</div>
            </section>
            <section className="content"></section>
        </div>
    );
};

Work.propTypes = {};

export default Work;
