import React from 'react';
import PropTypes from 'prop-types';

import './certificate.css';

const Certificate = ({ props }) => {

    let certClass = 'certificate flex flex-direction-row flex-align-center flex-wrap';
    let logoUrl = props.logo ? require(`../assets/images/${props.logo}`) : '';

    return (
        <div className={certClass}>
            <div className="cert-logo"
                 data-aos="fade-up"
                 data-aos-duration="600"
                 data-aos-easing="ease-in-sine">
                {
                    logoUrl ? (
                        <img src={logoUrl} alt={props.title} className="icon-cert" />
                    ) : ''
                }
            </div>
            <div className="cert-content">
                <div>
                    <p className="title"
                       data-aos="fade-up"
                       data-aos-duration="400"
                       data-aos-easing="ease-in-sine">
                        <span className="text-brand">{props.name}</span>
                    </p>
                    <p className="subtitle"
                       data-aos="fade-up"
                       data-aos-duration="800"
                       data-aos-easing="ease-in-sine">
                        {props.provider}
                    </p>
                    <table className="content jf-m-vertical_medium"
                           data-aos="fade-up"
                           data-aos-duration="1000"
                           data-aos-easing="ease-in-sine">
                        <tbody>
                            <tr>
                                <td>Status:</td>
                                <td>{props.status}</td>
                            </tr>
                            <tr>
                                <td>Issue Date:</td>
                                <td>{props.issued_date}</td>
                            </tr>
                            <tr>
                                <td>Expiry Date:</td>
                                <td>{props.expiration}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

Certificate.propTypes = {
    name: PropTypes.string,
    provider: PropTypes.string,
    issued_date: PropTypes.string,
    expiration: PropTypes.string,
    status: PropTypes.string,
    logo: PropTypes.string
};

export default Certificate;
