import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";

import Button from "./button";
import user from "../assets/static/data/user.json";
import navitems from "../assets/static/data/navitems.json";
import jsonlogo from "../assets/svg/JF_192_Black_V2.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {

    const [header, setHeader] = useState({
        initScroll: false,
        mobMenuActive: false
    });

    useEffect(() => {

        if (!header.initScroll) {
            window.addEventListener('scroll', event => {
                
                setHeader({ ...header, initScroll: true });

                let headerPos = window.scrollY;
                let headerContainer = document.querySelector('.header-container');

                if (headerPos && headerPos > 40) {
                    headerContainer.classList.add('scrolled');
                } else {
                    headerContainer.classList.remove('scrolled');
                }
            });
        }
    }, [header]);

    const navigate = useNavigate();

    function navto(path) {
        document.body.style.overflow = 'auto';
        document.querySelector('.mob-menu').classList.remove('active');
        
        setHeader({
            ...header,
            mobMenuActive: false
        });

        document.querySelectorAll(".nav-menus").forEach((menu) => {
            menu.classList.remove("active");
        });

        if (path.indexOf('http') !== -1) {
            window.open(path, '_self');
        } else {
            navigate(path);
        }
    }

    function sendEmail(email) {
        var linkTag = document.createElement('a');
        linkTag.href = email;
        linkTag.click();
    }

    function toggleNavMenu(event) {
        event.stopPropagation();
        event.currentTarget.querySelector("ul").classList.toggle("active");
    }

    function toggleMobMenu() {
        let isActive = !header.mobMenuActive;

        setHeader({
            ...header,
            mobMenuActive: isActive
        });

        const mobMenu = document.querySelector('.mob-menu');

        if (isActive) {
            document.body.style.overflow = 'hidden';
            mobMenu.classList.add('active');
            return;
        }
        document.body.style.overflow = 'auto';
        mobMenu.classList.remove('active');
    }

    return (
        <div className="header-container flex flex-sb">
            <div className="menus flex flex-align-center">
                <div className="header-logo flex flex-align-center"
                     data-aos="fade-left"
                     data-aos-duration="500"
                     data-aos-easing="ease-in-sine">
                    <img className="logo-image flex flex-align-center"
                        src={jsonlogo}
                        height="30"
                        alt="logo"
                        onClick={(event) => { navto('/')}}/>
                    <span className="jf-p-left_small">{user.first_name}</span>
                </div>
                <div className="desk-nav">
                    <ul>
                        {navitems.navs.map((nav) => {
                            let iconClass = nav.submenus?.length ? "jf-p-left_small" : "jf-hide";
                            let optionClass = nav.submenus?.length ? "nav-menus" : "jf-hide";
                            
                            if (!nav.inTab) return '';

                            return (
                                <li key={nav.id}
                                    onClick={(event) => {
                                        nav.submenus?.length ? toggleNavMenu(event) : navto(nav.route);
                                    }}>

                                    <label>
                                        <span>{nav.name}</span>
                                        <FontAwesomeIcon icon="chevron-down" className={iconClass} size="2xs" />
                                    </label>

                                    <ul className={optionClass}>
                                        {nav.submenus?.map((menu) => {
                                            return (
                                                <li key={menu.id}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        event.currentTarget.parentElement.classList.remove("active");
                                                        navto(menu.route);
                                                    }}>

                                                    <FontAwesomeIcon icon={menu.icon} className="jf-p-right_small" />
                                                    <span>{menu.name}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className="header-actions flex flex-align-center flex-direction-row">
                <div className="send-email">
                    <Button
                        props={{
                            type: "neutral",
                            label: "Send Email",
                            icon: "paper-plane",
                            data: `mailto:${user.email}`,
                            handleClick: sendEmail,
                        }}
                    />
                </div>
                <div className="mob-menu-toggle">
                    <FontAwesomeIcon icon="fa-solid fa-bars" size="2x" onClick={toggleMobMenu}/>
                </div>
            </div>
            <div className="mob-menu">
                <div className="mob-menu-content">
                    <div className="right">
                        <FontAwesomeIcon icon="fa-solid fa-xmark" size="2x" onClick={toggleMobMenu} />
                    </div>
                    <div className="jf-m-top_xlarge jf-m-bottom_medium mob-menus">
                        <h1 className="center"><span className="text-brand">Hello!</span></h1>
                        <ul>
                            {navitems.navs.map((nav) => {
                                let iconClass = nav.submenus?.length ? "jf-p-left_small" : "jf-hide";
                                let optionClass = nav.submenus?.length ? "nav-menus" : "jf-hide";
                                
                                if (!nav.inTab) return '';
                                
                                return (
                                    <li key={nav.id}
                                        onClick={(event) => {
                                            nav.submenus?.length ? toggleNavMenu(event) : navto(nav.route);
                                        }}>

                                        <label>
                                            <span>{nav.name}</span>
                                            <FontAwesomeIcon icon="chevron-down" className={iconClass} size="2xs" />
                                        </label>

                                        <ul className={optionClass}>
                                            {nav.submenus?.map((menu) => {
                                                return (
                                                    <li key={menu.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.currentTarget.parentElement.classList.remove("active");
                                                            navto(menu.route);
                                                        }}>

                                                        <FontAwesomeIcon icon={menu.icon} className="jf-p-right_small" />
                                                        <span>{menu.name}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="component-divider">
                        <div className="jf-m-bottom_medium">Follow me on:</div>
                        <ul className="socials">
                            {
                                user.socials.map((social, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={social.url} target="_blank" rel="noreferrer" title={social.name}>
                                                <FontAwesomeIcon icon={social.icon} className="social-icon" />
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="send-email jf-p-vertical_small">
                        <Button
                            props={{
                                type: "neutral",
                                label: "Send Email",
                                icon: "paper-plane",
                                data: `mailto:${user.email}`,
                                handleClick: sendEmail,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
