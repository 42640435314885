import React from 'react';
import Button from "../components/button";

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './awards.css';

import Footer from '../components/footer';
import awards from '../assets/static/data/awards.json';

const Awards = (props) => {

    const navigate = useNavigate();

    function navto(path) {
        navigate(path);
    }

    return (
        <section className="page page-award section-2fr">
            <div className="awards">
                <h1 className="jf-m-bottom_xlarge">
                    <FontAwesomeIcon icon="fa-solid fa-trophy" className="text-brand jf-m-bottom_small award-logo" size="2x" />
                    <p className="title">{awards.title}</p>
                    <p className="subtitle">{awards.subtitle}</p>
                </h1>
                <p className="content jf-m-bottom_xlarge">{awards.content}</p>
                {
                    awards.awards.map((award, index) => {
                        return (
                            <div className="award-items">
                                <h1 className="center">{award.title}</h1>
                                {
                                    award.images.map((image, index) => {
                                        let imgUrl = require(`../assets/images/${image.name}`)
                                        return (
                                            <img key={index} src={imgUrl} alt="trophy" width="100%" className="award-image" />
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
            <Footer />
        </section>
    );
};

Awards.propTypes = {};

export default Awards;
