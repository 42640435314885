const util = {
    validateEmail: function(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    },
    toggleError: function(element, valid) {
        if (valid) {
            element.classList.remove('jf-error');
            return;
        }
        element.classList.add('jf-error');
    },
    toggleErrorMessage: function(element, valid, message) {
        if (valid) {
            element.textContent = '';
            return;
        }
        element.textContent = message;
    },
    toggleInputError(element, valid, message) {
        this.toggleError(element, valid);
        this.toggleErrorMessage(element, valid, message);
    },
    formatText(text, args) {
        return text.replace(/{(\d+)}/g, function(match, number) { 
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
            ;
        });
    }
}

export default util