
import React from 'react';
import './certifications.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/footer';
import Certificate from '../components/certificate';
import SendMessage from '../components/sendmessage';

import certfications from '../assets/static/data/certifications.json';

const Certifications = (props) => {
    return (
        <section className="page page-certifications section-2fr">
            <div className="certifications">
                <h1 className="jf-m-bottom_xlarge">
                    <FontAwesomeIcon icon="fa-solid fa-award" className="text-brand jf-m-bottom_small" size="2x" />
                    <p className="my-cert title">{certfications.title}</p>
                    <p className="my-cert subtitle">{certfications.subtitle}</p>
                </h1>
                <div>
                    {
                        certfications.certificates.map((certificate, index) => {
                            return (
                                <div key={index} className="cert-item">
                                    <Certificate props={certificate} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="cert-contact component-divider">
                <SendMessage />
            </div>
            <Footer />
        </section>
    );
};

Certifications.propTypes = {};

export default Certifications;
