import React from "react";
import './about.css';

import Story from '../components/story';

import stories from '../assets/static/data/stories.json';
import user from '../assets/static/data/user.json';

import SendMessage from '../components/sendmessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/footer';
import util from '../components/util';

const About = (props) => {

    function scrollToStory() {
        document.querySelector('.page-story').scrollIntoView({ behavior: "smooth" });
    }

    let about_content = util.formatText(user.about_content,
        [new Date().getFullYear() - user.location.start_year]);

	return (
        <div>
            <section className="page page-about">
                <div className="banner">
                    <div className="intro-about flex flex-direction-column flex-justify-center">
                        <p className="title"
                           data-aos="fade-right"
                           data-aos-duration="800"
                           data-aos-easing="ease-in-sine">
                            <span className="text-invert-light">{user.about_title}</span>
                        </p>
                        <p className="subtitle"
                           data-aos="fade-zoom-in"
                           data-aos-easing="ease-in-sine"
                           data-aos-duration="1000">
                            {user.about_subtitle}
                        </p>
                        <p className="content jf-m-vertical_medium"
                           data-aos="fade-left"
                           data-aos-easing="ease-in-sine"
                           data-aos-duration="800">
                            {about_content}
                        </p>
                    </div>
                    <div className="scroll-more">
                        <FontAwesomeIcon icon="chevron-down" className="bounce" size="xl" onClick={scrollToStory}></FontAwesomeIcon>
                    </div>
                </div>
                <div className="intro-about-mob">
                    <div className="intro-about-mob jf-m-vertical_large">
                        <p className="title"
                           data-aos="fade-up"
                           data-aos-duration="400"
                           data-aos-easing="ease-in-sine">
                            <span className="text-brand">{user.about_title}</span>
                        </p>
                        <p className="subtitle"
                           data-aos="fade-up"
                           data-aos-easing="ease-in-sine"
                           data-aos-duration="1000">
                            {user.about_subtitle}
                        </p>
                        <p className="content jf-m-vertical_medium"
                           data-aos="fade-up"
                           data-aos-easing="ease-in-sine"
                           data-aos-duration="600">
                            {user.about_content}
                        </p>
                    </div>
                </div>
                <div className="page-story">
                    <h1 className="title center">
                        <span className="text-brand"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-sine"
                              data-aos-duration="600">Things I love...</span>
                        </h1>
                    {
                        stories.stories.map( (item, index) => {
                            item.reverse = ((index % 2) === 0);
                            return (
                                <div key={index} className="story-row">
                                    <Story props={item} />
                                </div>
                            );
                        })
                    }
                </div>
                <div className="about-contact component-divider">
                    <SendMessage />
                </div>
                <div className="">
                    <Footer />
                </div>
            </section>
        </div>
    );
};

export default About;
