import React from "react";
import PropTypes from "prop-types";

import './button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const button = ({ props }) => {
    let btnClass = `btn ${props.type}`;
    let btnIconClass = `btn-icon ${props.icon ? (props.icon + ' jf-p-left_small') : 'js-hide'}`;

    let onclick = props.handleClick;
    let btnIcon = props.icon
        ? <FontAwesomeIcon icon={props.icon} className={btnIconClass} /> : '';

    return (
        <button className={btnClass} onClick={event => { onclick(props.data) }}>
            <span>{props.label}</span>
            {btnIcon}
        </button>
    );
};

button.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
};

export default button;
