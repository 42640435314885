import React from 'react';
import Button from "../components/button";

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './blog.css';

import Footer from '../components/footer';
import blogs from '../assets/static/data/blogs.json';

const Blog = (props) => {

    const navigate = useNavigate();

    function navto(path) {
        navigate(path);
    }

    return (
        <section className="page page-blog section-2fr">
            <div className="blogs">
                <h1 className="jf-m-bottom_xlarge">
                    <FontAwesomeIcon icon="fa-brands fa-blogger" className="text-brand jf-m-bottom_small blog-logo" size="2x" />
                    <p className="title">{blogs.title}</p>
                    <p className="subtitle">{blogs.subtitle}</p>
                </h1>
                <p className="content jf-m-bottom_xlarge">{blogs.content}</p>
                <div className="center">
                    <div className="jf-m-top_large">
                        <iframe src="https://www.youtube.com/embed/8o3SOtn0eh8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="jf-m-top_large">
                        <iframe src="https://www.youtube.com/embed/33w784mUD2A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <div className="jf-m-top_large">
                        <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@jaysonf04" data-unique-id="jaysonf04" data-embed-type="creator">
                            <section>
                                <a target="_blank" href="https://www.tiktok.com/@jaysonf04?refer=creator_embed" rel="noreferrer">@jaysonf04</a>
                            </section>
                        </blockquote>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
};

Blog.propTypes = {};

export default Blog;
