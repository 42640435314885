import React from 'react';
import PropTypes from 'prop-types';

import './story.css';

const story = ({props}) => {

    let storyClass = `story flex flex-direction-${props.reverse ? 'row' : 'row-reverse'} flex-align-center`;
    let logoUrl = props.logo ? require(`../assets/images/${props.logo}`) : '';

    return (
        <div className={storyClass}>
            <div className="story-logo"
                 data-aos="fade-up"
                 data-aos-offset="30"
                 data-aos-duration="800"
                 data-aos-easing="ease-in-sine">
                    {
                        logoUrl ? (
                            <img src={logoUrl} alt={props.title} />
                        ) : ''
                    }
            </div>
            <div className="story-content">
                <div>
                    <p className="title"
                       data-aos="fade-up"
                       data-aos-offset="30"
                       data-aos-duration="400"
                       data-aos-easing="ease-in-sine">
                        <span className="text-brand">{props.title}</span>
                    </p>
                    <p className="subtitle"
                       data-aos="fade-up"
                       data-aos-offset="30"
                       data-aos-easing="ease-in-sine"
                       data-aos-duration="800">
                        {props.subtitle}
                    </p>
                    <p className="content jf-m-vertical_medium"
                       data-aos="fade-up"
                       data-aos-offset="30"
                       data-aos-easing="ease-in-sine"
                       data-aos-duration="1200">
                        {props.content}
                    </p>
                </div>
            </div>
        </div>
    );
};

story.propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.string,
    reverse: PropTypes.bool
};

export default story;
