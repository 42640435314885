import React from 'react';
import Button from "../components/button";
import { useNavigate } from 'react-router-dom';

import './invalid.css';
import messages from '../assets/static/data/messages.json';
import Footer from '../components/footer';

const Invalid = (props) => {

	var logoUrl = require('../assets/images/no-results.png');

	const navigate = useNavigate();

	function navto(path) {
		navigate(path);
	}

	return (
		<section className="page page-invalid section-2fr">
			<div className="invalid">
				<div className="jf-morphism-white form-container">
					<div className="jf-m-vertical_xlarge">
						<img src={logoUrl} alt="logo" className="invalid-icon" />
					</div>
					<h1>{messages.invalid_page_title}</h1>
					<h2>{messages.invalid_page_subtitle}</h2>
					<p>{messages.invalid_page_message}</p>

					<div className="btn-result-redirect jf-m-vertical_xlarge">
						<Button
							props={{
								type: "brand",
								label: "Go to Home",
								icon: "fa-home-user",
								data: "/",
								handleClick: navto,
							}}
						/>
					</div>
				</div>
			</div>
			<Footer />
		</section>
	);
};

Invalid.propTypes = {};

export default Invalid;
