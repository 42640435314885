import "./app.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import Home from "./pages/home";
import About from "./pages/about";
import Work from "./pages/work";
import Projects from "./pages/projects";
import Experiences from "./pages/experiences";
import Certifications from "./pages/certifications";
import Awards from './pages/awards';
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import Invalid from "./pages/invalid";
import Result from './pages/result';

import Header from "./components/header";
import ScrollToTop from "./components/scrollToTop";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faPaperPlane,
    faChevronDown,
    faDiagramProject,
    faTrophy,
    faListCheck,
    faAward,
    faHouse,
    faHomeUser,
    faLocationDot,
    faCircle,
    faLock,
    faBars,
    faXmark,
    faCamera
} from "@fortawesome/free-solid-svg-icons";

import {
    faUser,
    faBuilding,
    faClock as regularClock,
    faPaperPlane as regularPaperPlane
} from "@fortawesome/free-regular-svg-icons";

import {
    faFacebook,
    faYoutube,
    faTiktok,
    faInstagram,
    faGithub,
    faSalesforce,
    faReddit,
    faMeetup,
    faLinkedin,
    faBlogger
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faLock,
    faBars,
    faXmark,
    faCamera,
    faLocationDot,
    faCircle,
    faPaperPlane,
    faChevronDown,
    faDiagramProject,
    faBuilding,
    faTrophy,
    faListCheck,
    faAward,
    faUser,
    faHouse,
    faHomeUser,
    regularClock,
    regularPaperPlane,
    faFacebook,
    faYoutube,
    faTiktok,
    faInstagram,
    faReddit,
    faMeetup,
    faLinkedin,
    faGithub,
    faSalesforce,
    faBlogger
);

const app = () => {
    return (
        <Router basename="/">
            <ScrollToTop>
                <div className="app">
                    <header className="header">
                        <Header />
                    </header>

                    <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route exact path="/about" element={<About />}></Route>
                        <Route exact path="/work" element={<Work />}></Route>
                        <Route exact path="/projects" element={<Projects />}></Route>
                        <Route exact path="/experiences" element={<Experiences />}></Route>
                        <Route exact path="/certifications" element={<Certifications />}></Route>
                        <Route exact path="/awards" element={<Awards />}></Route>
                        <Route exact path="/contact" element={<Contact />}></Route>
                        <Route exact path="/blog" element={<Blog />}></Route>
                        <Route exact path="/invalid-page" element={<Invalid />}></Route>
                        <Route exact path="/result" element={<Result />}></Route>
                        <Route path="*" element={<Navigate to="/invalid-page" />}/>
                    </Routes>
                </div>
            </ScrollToTop>
        </Router>
    );
};

export default app;
