import React from 'react';
import Button from "../components/button";

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './experiences.css';

import Footer from '../components/footer';
import experiences from '../assets/static/data/experiences.json';

const Experiences = (props) => {

    const navigate = useNavigate();

    function navto(path) {
        navigate(path);
    }

    return (
        <section className="page page-exp section-2fr">
            <div className="exps">
                <h1 className="jf-m-bottom_xlarge">
                    <FontAwesomeIcon icon="fa-regular fa-building" className="text-brand jf-m-bottom_small exp-logo" size="2x" />
                    <p className="title">{experiences.title}</p>
                    <p className="subtitle">{experiences.subtitle}</p>
                </h1>
                <p className="content jf-m-bottom_xlarge">{experiences.content}</p>
                <div className="btn-exp jf-p-vertical_large">
                    <Button
                        props={{
                            type: "brand",
                            label: "Send Message",
                            icon: "",
                            data: '/contact',
                            handleClick: navto,
                        }}
                    />
                </div>
            </div>
            <Footer />
        </section>
    );
};

Experiences.propTypes = {};

export default Experiences;
