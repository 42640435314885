import React from 'react';
import Button from "../components/button";
import { useLocation, useNavigate } from 'react-router-dom';

import './result.css';

const Result = (props) => {
    const { state } = useLocation();
    const { logo, title, subtitle, message } = state ? state : {};

    var logoUrl = logo ? require(`../assets/images/${logo}`) : '';

    const navigate = useNavigate();
    
    function navto(path) {
        navigate(path);
    }

    return (
        <section className="page page-result banner">
            <div className="jf-p-vertical_large jf-morphism-white form-container">
                {
                    logo ? (
                        <div>
                            <img src={logoUrl} alt="logo" className="result-icon" />
                        </div>
                    ) : ''
                }
                <h1>{title}</h1>
                <h2>{subtitle}</h2>
                <p>{message}</p>
                
                <div className="btn-result-redirect jf-m-vertical_xlarge">
                    <Button
                        props={{
                            type: "brand",
                            label: "Back to Home",
                            icon: "fa-home-user",
                            data: "/",
                            handleClick: navto,
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default Result;