import React from 'react';
import user from '../assets/static/data/user.json';
import './footer.css';

import jsonlogo from '../assets/svg/JF_192_Black_V2.svg';
import navitems from '../assets/static/data/navitems.json';

import { useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = (props) => {
    const navigate = useNavigate();

    function navto(path) {
        navigate(path);
    }
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="logo-social flex flex-sb flex-wrap">
                    <div className="jf-logo flex flex-align-center" onClick={() => navto('/')}>
                        <img src={jsonlogo} height="30" alt="logo"/>
                        <span>{user.name}</span>
                    </div>
                    <div className="socials">
                        <span>Follow me on:</span>
                        <ul>
                            {
                                user.socials.map((social, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={social.url} target="_blank" rel="noreferrer" title={social.name}>
                                                <FontAwesomeIcon icon={social.icon} className="social-icon" />
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="navs">
                    <ul className="jf-p-vertical_xsmall navs-social">
                        {
                            user.social_business.map((social, index) => {
                                return (
                                    <li key={index}>
                                        <a href={social.url} target="_blank" rel="noreferrer" title={social.name}>
                                            <FontAwesomeIcon icon={social.icon} className="social-icon" />
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <ul className="jf-p-bottom_xsmall">
                        {
                            navitems.navs.map((nav) => {
                                return (
                                    nav.infooter ? (
                                        <li key={nav.id} title={nav.name}>
                                            <NavLink to={nav.route}>{nav.name}</NavLink>
                                        </li>
                                    ) : ''
                                );
                            })
                        }
                    </ul>
                </div>
                
                <div className="groups">
                    <div>© 2022 {user.name} </div>
                    <div>React JS | Salesforce | Trailblazer | Figma | Adobe Photoshop</div>
                    <div>
                        <FontAwesomeIcon icon="fa-solid fa-location-dot" className="social-icon" />
                        <span>&nbsp; {user.location.city}, {user.location.country}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {};

export default Footer;
