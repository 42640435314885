import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import './sendmessage.css';
import avatar from '../assets/images/avatar2.png';
import Button from '../components/button';

import messages from '../assets/static/data/messages.json';
import util from '../components/util';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import emailjs from 'emailjs-com';

const SendMessage = (props) => {
    const [contact, setContact] = useState({
        didSend: false,
        loading: false
    });

    useEffect(() => {
        let inputs = document.querySelectorAll('input, select, textarea');

        inputs.forEach(input => {
            input.addEventListener('focusout', () => {
                if (contact.didSend) {
                    isInputValid(input);
                }
            });
        });
    });

    const navigate = useNavigate();

    function send() {
        setContact({ ...contact, didSend: true, loading: true });

        if (hasErrors()) {
            setTimeout(() => {
                setContact({ ...contact, loading: false });
            }, 550);
            return;
        }

        // process submit
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            {
                name: document.querySelector('input[name="name"').value,
                email: document.querySelector('input[name="email"').value,
                message: document.querySelector('textarea[name="message"').value,
            },
            process.env.REACT_APP_EMAILJS_USERID
        ).then( result => {

            console.log(result.text);

            setTimeout(() => {
                setContact({ ...contact, loading: false });

                navigate('/result',
                    {
                        state: {
                            logo: 'email-sent.gif',
                            title: messages.form_success_title,
                            subtitle: messages.form_success_subtitle,
                            message: messages.form_success_message
                        }
                    });
            }, 1500);
        });
    }

    function hasErrors() {
        let hasError = false;

        let inputs = document.querySelectorAll('input, select, textarea');
        
        inputs.forEach(input => {
            let valid = isInputValid(input);

            // invalid
            if (!hasError && !valid) {
                hasError = true;
            }
        });

        return hasError;
    }

    function isInputValid(input) {
        let isValid = true;
        let parent = input.parentElement;
        
        util.toggleError(input.nextElementSibling, true);

        // Required input validation
        if (input.required) {
            let blank = input.required && !input.value;
            util.toggleError(parent, !blank);

            if (blank) isValid = false;
        }
        
        // Input specific validation
        if (input.type === 'email' && input.value) {
            let validEmail = util.validateEmail(input.value);

            util.toggleError(parent, validEmail);
            util.toggleInputError(input.nextElementSibling, validEmail, messages.form_invalid_email);

            if (isValid && !validEmail) {
                isValid = false;
            }
        }

        return isValid;
    }
    
    return (
        <div className="message">
            <div className="jf-p-vertical_large jf-morphism-white flex form-container">

                {
                    contact.loading ? (
                        <div className="loader">
                            <div className="spinner"></div>
                        </div>
                    ) : ''
                }
                
                <div className="title flex flex-justify-center flex-direction-column">
                    <h1 data-aos="fade-down"
                        data-aos-duration="250"
                        data-aos-easing="ease-in-sine">Send me a message...</h1>

                    <div className="avatar"
                         data-aos="flip-right"
                         data-aos-duration="250"
                         data-aos-easing="ease-in-sine">
                        <img src={avatar} alt="avatar" />
                    </div>
                </div>
                <div className="form"
                     data-aos="zoom-in"
                     data-aos-duration="250"
                     data-aos-easing="ease-in-sine">
                    <div className="instruction flex flex-sb">
                        <div>
                            Fill in the form below and I'll get back to you as soon as I can.
                        </div>
                        <div>
                            <p>jayson@forceguru.io <FontAwesomeIcon icon="fa-regular fa-paper-plane" className="jf-p-left_xsmall" /></p>
                            <p>9:00am - 5:00pm <FontAwesomeIcon icon="fa-regular fa-clock" className="jf-p-left_xsmall" /></p>
                        </div>
                        
                    </div>
                    <div className="inputs">
                        <div className="flex flex-sb flex-align-center">
                            <h1>Contact Details</h1>
                            <img src={avatar} alt="avatar" className="img-avatar-mob"/>
                        </div>
                        <div>
                            <label>Name <span className="text-brand">*</span></label>
                            <input name="name" type="text" required></input>
                            <span></span>
                        </div>
                        <div className="jf-p-vertical_medium">
                            <label>Email <span className="text-brand">*</span></label>
                            <input name="email" type="email" required></input>
                            <span></span>
                        </div>
                        <div>
                            <label>Message</label>
                            <textarea name="message" cols="40" rows="8"></textarea>
                            <span></span>
                        </div>
                    </div>
                    <div className="btn-contact jf-m-vertical_small">
                        <Button
                            props={{
                                type: "brand",
                                label: "Send",
                                icon: "",
                                data: "",
                                handleClick: send,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

SendMessage.propTypes = {};

export default SendMessage;
