import React from "react";
import PropTypes from "prop-types";

import Landing from "../components/landing";

const Home = (props) => {
	return (
		<div>
			<section>
				<Landing />
			</section>
		</div>
	);
};

Home.propTypes = {};

export default Home;
